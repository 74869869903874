var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        {
          staticClass: "mb-4",
          attrs: { title: _vm.$t("marketing_place.config") },
        },
        [
          _c("div", { staticClass: "w-full break-all text-center flex mt-4" }, [
            _c("label", { staticClass: "mr-4" }, [
              _c("b", [
                _vm._v(_vm._s(_vm.$t("marketing_place.encryp_param")) + ":"),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex grid mb-2 mt-4" }, [
            _c("div", { staticClass: "w-full break-all text-left flex" }, [
              _c("span", [_vm._v(_vm._s(_vm.config.encryp_param))]),
            ]),
            _c(
              "div",
              { staticClass: "flex justify-end mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.config.encryp_param,
                        expression: "config.encryp_param",
                        arg: "copy",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.onSucceddCopy,
                        expression: "onSucceddCopy",
                        arg: "success",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onErrorCopy,
                        expression: "onErrorCopy",
                        arg: "error",
                      },
                    ],
                    staticClass: "footer-button",
                    attrs: { type: "border", icon: "content_copy" },
                  },
                  [_vm._v(_vm._s(_vm.$t("copiar")))]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "w-full break-all text-center flex mt-4" }, [
            _c("label", [
              _c("b", [_vm._v(_vm._s(_vm.$t("marketing_place.url")) + ":")]),
            ]),
          ]),
          _c("div", { staticClass: "flex grid mb-2 mt-4" }, [
            _c("div", { staticClass: "w-full break-all text-left flex" }, [
              _c(
                "a",
                {
                  staticClass: "action-link text-primary",
                  attrs: { href: _vm.config.url, target: "blank" },
                },
                [_vm._v(" " + _vm._s(_vm.config.url) + " ")]
              ),
            ]),
            _c(
              "div",
              { staticClass: "flex justify-end mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.config.url,
                        expression: "config.url",
                        arg: "copy",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.onSucceddCopy,
                        expression: "onSucceddCopy",
                        arg: "success",
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onErrorCopy,
                        expression: "onErrorCopy",
                        arg: "error",
                      },
                    ],
                    staticClass: "footer-button",
                    attrs: { type: "border", icon: "content_copy" },
                  },
                  [_vm._v(_vm._s(_vm.$t("copiar")))]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }