import Rest from '../Rest'

export default class MarketingPlaceService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/marketing_place'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  getMarketingPlaceConfig() {
    return this.get('/config').then(data => {
      return data
    })
  }

  getMarketingPlaceData(encrypParam : string) {
    return this.get(`/store/${encrypParam}`).then(data => {
      return data
    })
  }

}
