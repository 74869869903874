<template>
  <div>
    <vx-card class="mb-4"
      :title="$t('marketing_place.config')">


      <div class="w-full break-all text-center flex mt-4">
        <label class="mr-4">
          <b>{{ $t('marketing_place.encryp_param') }}:</b>
        </label>
      </div>
      <div class="flex grid mb-2 mt-4">
        <div class="w-full break-all text-left flex">
            <span>{{config.encryp_param}}</span>
        </div>
        <div class="flex justify-end mt-5">
          <vs-button
            type="border"
            icon="content_copy"
            class="footer-button"
            v-clipboard:copy="config.encryp_param"
            v-clipboard:success="onSucceddCopy"
            v-clipboard:error="onErrorCopy"
            >{{ $t('copiar') }}</vs-button>
        </div>
      </div>

      <div class="w-full break-all text-center flex mt-4">
        <label>
          <b>{{ $t('marketing_place.url') }}:</b>
        </label>
      </div>
      <div class="flex grid mb-2 mt-4">
        <div class="w-full break-all text-left flex">
          <a :href="config.url" target="blank" class="action-link text-primary">
            {{config.url}}
          </a>
        </div>
        <div class="flex justify-end mt-5">
          <vs-button
            type="border"
            icon="content_copy"
            class="footer-button"
            v-clipboard:copy="config.url"
            v-clipboard:success="onSucceddCopy"
            v-clipboard:error="onErrorCopy"
            >{{ $t('copiar') }}</vs-button>
      </div>
    </div>
    </vx-card>
  </div>
</template>

<script>

import standard from '@/services/standard'

import { isAdmin } from '@/util/Util'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import MarketingPlaceService from '@/services/api/MarketingPlaceService'


export default {
  directives: {

  },
  components: {
    ValidationProvider,
    ValidationObserver,

  },
  props: {

  },

  data: () => ({
    service: null,
    config: null

  }),
  computed: {
    validateForm() {

    }
  },
  beforeMount() {
    this.service = MarketingPlaceService.build(this.$vs)
  },
  mounted() {
    this.loadData()

  },
  methods: {
    loadData(id) {
      this.$vs.loading()

      this.service.getMarketingPlaceConfig().then(
        response => {
          this.config = response
        },
        error => {
          this.$vs.loading.close()
        }
      ).finally(() => {this.$vs.loading.close()})
    },
    onSucceddCopy() {
      this.notifySuccess(this.$vs, this.$t('copiado-com-sucesso'), 2000, 'center-top')
    },
    onErrorCopy() {
      this.notifyError(this.$vs, this.$t('nao-foi-possivel-copiar'))
    },
    isAdmin() {
      return isAdmin()
    }
  }
}
</script>

<style>

</style>
